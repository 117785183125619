<template>
    <ul class="card-info">
        <li v-if="showHeader">{{ $translate('card_info', 'Kortfakta') }}</li>
        <li
            v-for="(info, index) in cardInfo"
            :key="index"
        >
            <span>
                {{ info.header }}
                <tooltip v-if="info.headerTooltipText" :tooltip-text="info.headerTooltipText" />
            </span>
            <span>
                <div class="inline" v-html="info.data" />
                <first-year-offer-tooltip v-if="info.dataTooltipText" :model="model" />
            </span>
        </li>
    </ul>
</template>

<script>
import Tooltip from '@/components/features/Tooltip.vue';
import FirstYearOfferTooltip from '@/components/card/FirstYearOfferTooltip.vue';
import { capitalize } from 'lodash';
export default {
    components: {
        Tooltip,
        FirstYearOfferTooltip
    },
    props: {
        model: {
            type: Object,
            required: true,
            default: () => {}
        },
        showHeader: {
            type: Boolean,
            required: false,
            default: true
        }
    },
    computed: {
        cardInfo() {
            let list = [
                {
                    header: this.$translate('annual_fee', 'Årsavgift'),
                    data: this.model.yearlyCostHtml,
                    show: true,
                    hasFirstYearOffer: this.model.hasFirstYearOffer,
                    headerTooltipText: this.$translate('tooltip_annual_fee'),
                    dataTooltipText: this.$translate('annual_fee_offer_tooltip')
                },
                {
                    header: this.$translate('max_credit', 'Högsta kredit'),
                    data: this.model.getMaxCreditString(this.$translate('unlimited_max_credit', 'Obegränsad')),
                    show: this.model.isCredit,
                    headerTooltipText: this.$translate('tooltip_max_credit')
                },
                {
                    header: this.$translate('interest', 'Ränta'),
                    data: this.model.interestString,
                    show: this.model.isCredit,
                    headerTooltipText: this.$translate('tooltip_interest_rate')
                },
                {
                    header: this.$translate('effective_interest', 'Effektiv ränta'),
                    data: this.model.effectiveInterestString,
                    show: this.model.isCredit,
                    headerTooltipText: this.$translate('tooltip_effective_interest_rate')
                },
                {
                    header: this.$translate('interest_free', 'Räntefritt'),
                    data: this.model.getInterestFreeDaysString(this.$translate('days', '{days} dagar')),
                    show: this.model.isCredit,
                    headerTooltipText: this.$translate('tooltip_interest_free_days')
                },
                {
                    header: this.$translate('card_type', 'Korttyp'),
                    data: capitalize(this.model.paymentNetwork),
                    show: true,
                    headerTooltipText: this.$translate('tooltip_card_type')
                },
                {
                    header: this.$translate('withdraw_fee', 'Uttagsavgift'),
                    data: this.model.getWithdrawalFeeString('{percentage} (min {amount})', '{percentage} + {amount}'),
                    show: true,
                    headerTooltipText: this.$translate('tooltip_withdrawal_fee')
                },
                {
                    header: this.$translate('currency_surcharge', 'Valutapåslag'),
                    data: this.model.getCurrencyExchangeFeeString(),
                    show: true,
                    headerTooltipText: this.$translate('tooltip_currency_surcharge')
                },
                {
                    header: this.$translate('avi_fee', 'Aviavgift'),
                    data: this.model.getAviFeeString('{paperInvoiceFee} ({eInvoiceFee} e-faktura)'),
                    show: this.model.isCredit,
                    headerTooltipText: this.$translate('tooltip_invoice_fee')
                },
                {
                    header: this.$translate('reminder_fee', 'Påminnelseavgift'),
                    data: this.model.reminderFeeString,
                    show: this.model.isCredit,
                    headerTooltipText: this.$translate('tooltip_reminder_fee')
                },
                {
                    header: this.$translate('late_payment_fee', 'Förseningsavgift'),
                    data: this.model.latePaymentFeeString,
                    show: this.model.isCredit,
                    headerTooltipText: this.$translate('tooltip_late_payment_fee')
                },
                {
                    header: this.$translate('overdraft_fee', 'Övertrasseringsavgift'),
                    data: this.model.overdraftFeeString,
                    show: this.model.isCredit,
                    headerTooltipText: this.$translate('tooltip_overdraft_fee')
                },
                {
                    header: this.$translate('compare_cards_min_amount_to_pay', 'Minsta belopp att betala'),
                    data: this.model.getInvoiceMinToPayString(),
                    show: true,
                    headerTooltipText: this.$translate('tooltip_min_amount_to_pay')
                },
                {
                    header: this.$translate('free_extra_card', 'Gratis extra kort'),
                    data: this.$formatBoolean(this.model.hasFreeExtraCard),
                    show: true,
                    headerTooltipText: this.$translate('tooltip_extra_card')
                }
            ];

            return list.filter(item => item.show);
        }
    }
};
</script>

<style lang="scss" scoped>
    .card-info {
        li::v-deep {
            @include flex(between);
            gap: 0.5rem;
            flex-wrap: nowrap !important;
            width: 100%;
            padding: 10px 0;
            @include border(bottom);
            &:last-child { border-bottom: 0; }

            span {
                &:first-child {
                    font-weight: bold;
                }
                &:last-child {
                    flex: unset !important;
                    text-align: right;
                    
                    @include device(desktop) {
                        flex:  1 !important;
                        text-align: left;
                    }
                }
                .inline {
                    display: inline;
                }
                i {
                    font-size: 1.2em;
                }
            }

            .standard-yearly-cost {
                color: red;
                margin-left: 5px;
                padding: 0!important;
                text-decoration: line-through;
            }
        }
    }
</style>
