// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_GET_URL_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/getUrl.js");
var ___CSS_LOADER_URL_IMPORT_0___ = require("../../../../../assets/fonts/hero/hero-new.otf");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(false);
var ___CSS_LOADER_URL_REPLACEMENT_0___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_0___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, "@font-face{font-family:\"Hero\";src:url(" + ___CSS_LOADER_URL_REPLACEMENT_0___ + ");font-weight:400;font-style:normal;font-display:swap}.font-text[data-v-1b83973b]{font-family:\"Open Sans\"}.font-header[data-v-1b83973b]{font-family:\"Hero\"}.card-info li[data-v-1b83973b]{display:flex;flex-wrap:wrap;justify-content:space-between;align-items:center;grid-gap:.5rem;gap:.5rem;flex-wrap:nowrap!important;width:100%;padding:10px 0;border:0;box-sizing:border-box;border-bottom:1px solid rgba(241,214,245,.7)}.card-info li[data-v-1b83973b]:last-child{border-bottom:0}.card-info li[data-v-1b83973b] span:first-child{font-weight:700}.card-info li[data-v-1b83973b] span:last-child{flex:unset!important;text-align:right}@media screen and (min-width:900px){.card-info li[data-v-1b83973b] span:last-child{flex:1!important;text-align:left}}.card-info li[data-v-1b83973b] span .inline{display:inline}.card-info li[data-v-1b83973b] span i{font-size:1.2em}.card-info li[data-v-1b83973b] .standard-yearly-cost{color:red;margin-left:5px;padding:0!important;text-decoration:line-through}", ""]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___;
